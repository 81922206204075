import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unslugify',
  standalone: true
})

export class UnslugifyPipe implements PipeTransform {

  transform(value: string = '', args?: any): any {
    value = value || ' ';
    return value[ 0 ].toLocaleUpperCase() + value.slice(1).toString().replace(/-/g, ' ').replace(/_/g, ' ').replace(/  /g, ' ');
  }
}
