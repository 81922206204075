import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Project } from '../../classes/project';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../services/project.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertsService } from '../../services/alerts.service';

import * as XLSX from 'xlsx';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ShareService } from '../../services/share.service';

import { Router } from '@angular/router';
import { User } from '../../classes/user';
import { deleteSpaces } from '../../utils';
import { UserService } from '../../services/user.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from '../header/header.component';
import { ProjectAnalyticsComponent } from '../project-analytics/project-analytics.component';
import { CandidatesTableComponent } from '../candidates-table/candidates-table.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'app-project',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatTooltipModule,
    HeaderComponent,
    ProjectAnalyticsComponent,
    CandidatesTableComponent,
    BsDropdownModule
  ],
  templateUrl: './project.component.html',
  styleUrls: [ './project.component.scss' ]
})
export class ProjectComponent implements OnInit {

  project: Project = new Project;
  statuses = Project.STATUS_DICT;
  resultsUrl: string;
  scoresUrl: string;

  modalRef: BsModalRef;
  candidateEmail: string;
  candidatefirstName: string;
  candidatelastName: string;
  registerUrl = '';

  toBeAddedCandidates: string[] = [];
  toBeAddedCandidatesFirstName: string[] = [];
  toBeAddedCandidatesLastName: string[] = [];

  candidatesAdded: number = 0;
  loading: boolean = false;
  displayCandidate: boolean = false;
  reportCandidateActive: boolean = false;
  user: User;
  permissions: {};
  boolAutomaticEmail = false;
  canViewResult = false;

  breadcrumbShow: boolean = false;
  @Output() breadcrumbEvent = new EventEmitter<boolean>();
  moveProject: boolean;

  singleCandidateError: 'invalid' | 'existing' = null;


  toBeAddedCandidatesFromFile = [];
  existingEmailsSerialized = [];
  invalidEmailsSerialized = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private modalService: BsModalService,
    private alertsService: AlertsService,
    public userService: UserService,
    private translateService: TranslateService,
    private router: Router,
    private _shareService: ShareService
  ) {
    this._shareService.changeEmitted_report$.subscribe(
      moveProject => {
        this.moveProject = moveProject;
      }
    );
  }

  ngOnInit() {

    this.user = this.userService.getUser();
    this.checkIfCanViewResult(this.user);
    this.subscribeCandidateParam();
    this.activatedRoute.params.subscribe(params => {
      this.project.id = parseInt(params[ 'project' ]);
      this.setProject();
    });
    this._shareService.emitChange(false);
  }

  setProject() {
    this.resultsUrl = '';
    this.scoresUrl = '';
    this.projectService.getProject(this.project.id).pipe().subscribe(
      response => {
        this.project = response;
        this.registerUrl = this.project.autoregister_url;
      });
  }

  addEmail() {
    if (this.candidateEmail) {
      this.checkCandidates([ deleteSpaces(this.candidateEmail) ], true);
    }
  }

  checkCandidates(emails: string[], singleCandidate: boolean) {
    this.projectService.checkCandidates(this.project.id, emails).pipe().subscribe(
      response => {
        if (singleCandidate) {
          this.serializeSingleCandidate(response.valid_emails, response.invalid_emails, response.existing_emails);
        } else {
          this.serializeCandidatesFromFile(response.valid_emails, response.invalid_emails, response.existing_emails);
        }
      }
    );
  }

  checkIfCanViewResult(user: User) {
    this.canViewResult = user?.permissions?.includes('candidates.view_result');
  }

  addEmailsFromFile() {
    this.checkCandidates(this.toBeAddedCandidatesFromFile.map(item => deleteSpaces(item.email)), false);
  }

  serializeSingleCandidate(validEmails: string[], invalidEmails: string[], existingEmails: string[]) {

    if (validEmails.length > 0 && !this.toBeAddedCandidates.includes(deleteSpaces(this.candidateEmail))) {

      this.toBeAddedCandidates = this.toBeAddedCandidates.concat(validEmails);
      this.toBeAddedCandidatesFirstName.push(this.candidatefirstName);
      this.toBeAddedCandidatesLastName.push(this.candidatelastName);
      this.candidateEmail = '';
      this.candidatefirstName = '';
      this.candidatelastName = '';
      this.singleCandidateError = null;

    } else if (invalidEmails.length && invalidEmails[ 0 ] === this.candidateEmail) {

      this.singleCandidateError = 'invalid';

    } else if (existingEmails.length && existingEmails[ 0 ] === this.candidateEmail) {

      this.singleCandidateError = 'existing';

    }
  }

  serializeCandidatesFromFile(validEmails: string[], invalidEmails: string[], existingEmails: string[]) {

    this.toBeAddedCandidatesFromFile.forEach(candidate => {
      const trimmedEmail = deleteSpaces(candidate.email);

      if (validEmails.includes(trimmedEmail) && !this.toBeAddedCandidates.includes(trimmedEmail)) {
        this.toBeAddedCandidates.push(trimmedEmail);
        this.toBeAddedCandidatesFirstName.push(candidate.name);
        this.toBeAddedCandidatesLastName.push(candidate.surname);
      }
    });

    if (existingEmails.length > 0) {
      this.existingEmailsSerialized = this.existingEmailsSerialized.concat(existingEmails);
    }

    if (invalidEmails.length > 0) {
      this.invalidEmailsSerialized = this.invalidEmailsSerialized.concat(invalidEmails);
    }
  }

  addCandidatesList() {
    this.projectService.addCandidates(this.project.id, this.toBeAddedCandidates, this.toBeAddedCandidatesFirstName, this.toBeAddedCandidatesLastName).pipe().subscribe(
      response => {
        this.setProject();
        this.candidatesAdded = this.toBeAddedCandidates.length;
        this.toBeAddedCandidates = [];
        this.toBeAddedCandidatesFirstName = [];
        this.toBeAddedCandidatesLastName = [];
        this.alertsService.setAlert({ type: "success", message: this.candidatesAdded + ' ' + this.translateService.instant("__candidatesAdded") });
        this.closeModalAddCandidates();
      },
      error => this.alertsService.setAlert({ type: "error", message: this.translateService.instant("__candidatesNotAdded") })
    );
  }

  generateProjectResults(modal) {
    if (!this.resultsUrl) {
      this.loading = true;
      this.projectService.generateProjectResults(this.project.id).pipe().subscribe(
        response => {
          this.resultsUrl = response.url;
          this.loading = false;
          this.showModal(modal);
        }, error => {
          this.loading = false;
          this.alertsService.setAlert({ type: "error", message: this.translateService.instant("__projectNotGenerated") });
        }
      );
    } else {
      this.showModal(modal);
    }
  }

  generateProjectScores(modal) {
    if (!this.scoresUrl) {
      this.loading = true;
      this.projectService.generateProjectScores(this.project.id).pipe().subscribe(
        response => {
          this.scoresUrl = response.url;
          this.loading = false;
          this.showModal(modal);
        }, error => {
          this.loading = false;
          this.alertsService.setAlert({ type: "error", message: this.translateService.instant("__projectNotGenerated") });
        }
      );
    } else {
      this.showModal(modal);
    }
  }

  showModal(modal) {
    this.modalRef = this.modalService.show(modal);
  }

  closeModal() {
    this.modalRef.hide();
  }

  closeModalAddCandidates() {
    this.toBeAddedCandidates = [];
    this.toBeAddedCandidatesFirstName = [];
    this.toBeAddedCandidatesLastName = [];

    this.toBeAddedCandidatesFromFile = [];

    this.existingEmailsSerialized = [];
    this.invalidEmailsSerialized = [];

    this.candidateEmail = '';
    this.candidatefirstName = '';
    this.candidatelastName = '';
    this.singleCandidateError = null;

    this.modalRef.hide();
  }

  removeCandidate(email) {
    let index = this.toBeAddedCandidates.indexOf(email);
    if (index !== -1) {
      this.toBeAddedCandidatesFromFile.splice(index, 1);
      this.toBeAddedCandidates.splice(index, 1);
      this.toBeAddedCandidatesFirstName.splice(index, 1);
      this.toBeAddedCandidatesLastName.splice(index, 1);
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) ? true : this.alertsService.setAlert({ type: "error", message: this.translateService.instant("__notValidEmail") + `: <${email}>` });
  }

  onFileChange(event: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[ 0 ];
      const ws: XLSX.WorkSheet = wb.Sheets[ wsname ];

      /* save data */
      const xlsRows = <String[]>XLSX.utils.sheet_to_json(ws, { header: 1 });

      xlsRows.forEach((row, index) => {
        if (row.length > 0 && index > 0) {
          this.toBeAddedCandidatesFromFile.push({ email: row[ 0 ], name: row[ 1 ], surname: row[ 2 ] });
        }
      });
    };
    reader.readAsBinaryString(target.files[ 0 ]);
  }


  subscribeCandidateParam() {
    this.activatedRoute.queryParamMap.subscribe(
      queryParam => {
        if (queryParam.has('candidate')) {
          this.reportCandidateActive = true;
        }
        else {
          this.reportCandidateActive = false;
        }
      }
    );
  }

  copyTextToClipboard(element) {
    element.select();
    document.execCommand('copy');
    element.setSelectionRange(0, 0);
  }

  logout() {
    this.userService.logout();
    this.router.navigate([ 'login' ]);
  }

}
