<div class="alert-component" [ngSwitch]="type" [ngClass]="type" [@slide]="showAlert">
  <div class="alert-icon" *ngIf="showAlert" >
      <i class="fas fa-times-circle" *ngSwitchCase="'error'"></i>
      <i class="fas fa-exclamation-triangle" *ngSwitchCase="'warning'"></i>
      <i class="fas fa-check-circle" *ngSwitchCase="'success'"></i>
      <i class="fas fa-info-circle" *ngSwitchCase="'info'"></i>
  </div>
  <div class="alert-content">
    {{message}}
  </div>
  <div class="alert-close" (click)="hideAlert()">
    <i class="fas fa-times"></i>
  </div>
</div>