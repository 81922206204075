import { Pipe, PipeTransform } from '@angular/core';
import { Candidate } from '../classes/candidate';

@Pipe({
  name: 'candidateStatus',
  standalone: true
})
export class CandidateStatusPipe implements PipeTransform {

  transform(candidate: Candidate): any {
    if (!candidate.registration) return 'Error';
    if (!candidate.start_tests) return 'Invited';
    if (!candidate.end_tests) return 'Doing';
    else return 'Evaluated';
  }

}
