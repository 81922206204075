<div class="project-wrapper">
  <app-header [ngClass]="{ 'display-width-header': displayCandidate }">
  </app-header>

  <div class="project-container">
    <div
      class="project-content"
      [ngClass]="moveProject ? 'project-content-report-active' : ''"
    >
      <div
        class="project-header"
        [ngClass]="{ 'display-width-header': displayCandidate }"
      >
        <div class="project-info text-container">
          <h2 class="first-row-header">
            {{ project.name }}
            <span
              class="table__cell"
              [ngClass]="project.status"
              [ngSwitch]="statuses[project.status]"
            >
              <div
                class="status_project"
                *ngSwitchCase="'Active'"
                style="color: #1ad16c"
              >
                <i class="material-icons">fiber_manual_record</i>
                <span class="material-icons-text">{{
                  statuses[project.status]
                }}</span>
              </div>
              <div
                class="status_project"
                *ngSwitchCase="'Disabled'"
                style="color: orange"
              >
                <i class="material-icons">fiber_manual_record</i>
                <span class="material-icons-text">{{
                  statuses[project.status]
                }}</span>
              </div>
              <div
                class="status_project"
                *ngSwitchCase="'Expired'"
                style="color: red"
              >
                <i class="material-icons">fiber_manual_record</i>
                <span class="material-icons-text">{{
                  statuses[project.status]
                }}</span>
              </div>
              <div
                class="status_project"
                *ngSwitchCase="'Pause'"
                style="color: grey"
              >
                <i class="material-icons">fiber_manual_record</i>
                <span class="material-icons-text">{{
                  statuses[project.status]
                }}</span>
              </div>
            </span>
            <div>&nbsp;&nbsp;&nbsp;</div>
          </h2>
          <h2 class="second-row-header">
            <div
              [ngClass]="{ 'padding-for-report': reportCandidateActive }"
              class="project-actions btn-group actions-menu"
              dropdown
            >
              <div class="bubble-zone">
                <span
                  class="pointer"
                  matTooltip="{{ '__inviteCandidates' | translate }}"
                  [matTooltipPosition]="'below'"
                  (click)="showModal(addCandidates)"
                >
                  <i class="fas fa-plus"></i>
                  {{ "__inviteCandidates" | translate }}</span
                >
              </div>
              <button dropdownToggle type="button">
                <div class="csv-button">
                  <img
                    class="img-in-bubble"
                    matTooltip="{{ '__projectActions' | translate }}"
                    src="../../assets/file-download-solid.svg"
                  />
                  {{ "__downloadCSV" | translate }}
                </div>
              </button>
              <ul
                *dropdownMenu
                id="project-actions-menu"
                class="dropdown-menu"
                role="menu"
              >
                <li role="menuitem"></li>
                <li
                  role="menuitem"
                  *ngIf="user?.isPartner() || user?.isAdmin()"
                ></li>
                <li role="menuitem"></li>
                <li role="menuitem" *ngIf="canViewResult || !user?.isClient()">
                  <span
                    class="dropdown-item dd-item"
                    (click)="generateProjectResults(generateResults)"
                  >
                    <i class="material-icons dd-icon">list</i>
                    <h3 class="dd-text pointer">
                      {{ "__results" | translate }}
                      <span class="dd-description">{{
                        "__generateCandidates" | translate
                      }}</span>
                    </h3>
                  </span>
                </li>
                <li role="menuitem">
                  <span
                    class="dropdown-item dd-item"
                    (click)="generateProjectScores(generateScores)"
                  >
                    <i class="material-icons dd-icon">equalizer</i>
                    <h3 class="dd-text pointer">
                      {{ "__scores" | translate }}
                      <span class="dd-description">{{
                        "__generateFile" | translate
                      }}</span>
                    </h3>
                  </span>
                </li>
              </ul>
            </div>
          </h2>
        </div>

        <p
          class="inform-p"
          *ngIf="
            project.instruments.length > 0 &&
            (user?.isPartner() || user?.isAdmin())
          "
        >
          <b>{{ "__instrument" | translate }}:</b>
          {{ project.instruments[0].name }} &nbsp;&nbsp;<br /><br />
          <b>{{ "__autoreg" | translate }}:</b>
          <span class="table__cell">
            <div
              class="status_project auto_register opacity-1"
              *ngIf="project.auto_register_enabled == false"
            >
              <span class="material-icons-text">No</span>
            </div>
            <div
              class="status_project auto_register opacity-1"
              *ngIf="project.auto_register_enabled == true"
            >
              <span class="material-icons-text">Yes</span>
              <input
                class="hide-url"
                #registerUrlElement
                [value]="registerUrl"
                [size]="45"
                readonly
              />
              <i
                class="material-icons pointer"
                matTooltip="Copy link"
                [matTooltipPosition]="'below'"
                (click)="copyTextToClipboard(registerUrlElement)"
                >file_copy</i
              >
            </div> </span
          ><br /><br />
          <b> UUID: </b>
          {{ project.uuid }}
          <input
            class="hide-url"
            #uuid
            [value]="project.uuid"
            [size]="45"
            readonly
          />
          <i
            class="material-icons pointer"
            matTooltip="Copy link"
            [matTooltipPosition]="'below'"
            (click)="copyTextToClipboard(uuid)"
            >file_copy</i
          >
        </p>
      </div>

      <div class="loader" *ngIf="loading">
        <i class="fas fa-spinner fa-pulse"></i>
      </div>
      <div
        *ngIf="displayCandidate !== undefined"
        [ngClass]="{ 'display-width': displayCandidate }"
      >
        <app-project-analytics
          [project]="project.id"
          [added]="candidatesAdded"
        ></app-project-analytics>
      </div>
      <div>
        <app-candidates-table
          [project]="project.id"
          [added]="candidatesAdded"
          (displayCandidate)="displayCandidate = $event"
        ></app-candidates-table>
      </div>
    </div>
  </div>
</div>

<ng-template #addCandidates>
  <div class="modal-header">
    <div class="modal-title">
      <h4 class="modal-title pull-left">{{ "__addCandidates" | translate }}</h4>
      <p>{{ "__insertEmailCandidates" | translate }}</p>
    </div>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModalAddCandidates()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="candidate-inputs">
      <div class="col-input">
        <label for="email"> {{ "__email" | translate }} </label>
        <input
          type="email"
          id="email"
          name="email"
          [(ngModel)]="candidateEmail"
        />
      </div>
      <div class="col-input">
        <label for="firstname"> {{ "__firstName" | translate }} </label>
        <input
          type="text"
          id="firstname"
          name="firstname"
          [(ngModel)]="candidatefirstName"
        />
      </div>
      <div class="col-input">
        <label for="lastname"> {{ "__lastName" | translate }} </label>
        <input
          type="text"
          id="lastname"
          name="lastname"
          [(ngModel)]="candidatelastName"
        />
      </div>
      <i
        class="material-icons new-element-icon"
        matTooltip="{{ '__candidateList' | translate }}"
        [matTooltipPosition]="'below'"
        (click)="addEmail()"
        >add_circle</i
      >
      <!-- (click)="addEmail(candidateEmail)" -->
    </div>
    <div class="individual-advice" *ngIf="singleCandidateError === 'invalid'">
      <p class="advice-span">
        <i class="material-icons">error</i>{{ "__invalidEmail" | translate }}
      </p>
      <p [innerHTML]="'__invalidEmailAdvice' | translate"></p>
    </div>
    <div class="individual-advice" *ngIf="singleCandidateError === 'existing'">
      <p class="advice-span">
        <i class="material-icons">error</i
        >{{ "__duplicateCandidate" | translate }}
      </p>
      <p [innerHTML]="'__duplicateCandidateAdvice' | translate"></p>
    </div>
    <div class="candidate-inputs second-row">
      <input
        #fileInput
        type="file"
        name="emails-file"
        (change)="onFileChange($event)"
        multiple="false"
        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <div class="input-csv">
        <span class="input input-csv" (click)="fileInput.click()"
          >{{ "__chooseFile" | translate }}
          <i class="material-icons">cloud_upload </i>
          <small>
            {{
              fileInput.files[0]
                ? fileInput.files[0].name
                : ("__noSelectFile" | translate)
            }}
          </small>
        </span>
        <span>
          <a href="/assets/sample-emails.xlsx" target="_self" download>{{
            "__downloadSample" | translate
          }}</a>
        </span>
      </div>
      <i
        class="material-icons new-element-icon"
        matTooltip="{{ '__candidateFile' | translate }}"
        [matTooltipPosition]="'below'"
        (click)="addEmailsFromFile()"
        >add_circle</i
      >
    </div>

    <!--
      <div class="automatic-emails-block">

          <div  (click)="boolAutomaticEmail = false" class="automatic-emails-option"> <input [checked]="boolAutomaticEmail == false"  class="automatic-email-check" type="checkbox"> Email automático al añadir los candidatos</div>
          
        
          <div (click)="boolAutomaticEmail = !boolAutomaticEmail" class="automatic-emails-option"> <input class="automatic-email-check" type="checkbox" [checked]="boolAutomaticEmail == true"> Programar envío de email automático</div>

          <input *ngIf="boolAutomaticEmail == true" class="datatime" type="datetime-local" name="datatime">


      </div>-->
    <div class="list-candidates">
      <div class="added-candidates">
        <div *ngIf="toBeAddedCandidates.length > 0">
          <div>
            {{ toBeAddedCandidates.length }}
            {{ "__candidatesCorrectly" | translate }}
          </div>
          <div
            class="candidates-list"
            *ngFor="let candidate of toBeAddedCandidates; let i = index"
            [attr.data-index]="i"
          >
            <i class="material-icons candidate-icon">account_circle</i>
            <p>
              {{ candidate }} - {{ toBeAddedCandidatesFirstName[i] }}
              {{ toBeAddedCandidatesLastName[i] }}
            </p>
            <i class="material-icons clear" (click)="removeCandidate(candidate)"
              >clear</i
            >
          </div>
        </div>
      </div>
      <div
        class="added-candidates"
        *ngIf="
          invalidEmailsSerialized.length > 0 ||
          existingEmailsSerialized.length > 0
        "
      >
        <div class="error-advice">
          {{ invalidEmailsSerialized.length + existingEmailsSerialized.length }}
          {{ "__candidatesConflictive" | translate }}
          <span class="bubble tooltip-advice"
            ><i class="fas fa-exclamation fa-lg"></i>
            <div class="popup-appearance tooltiptext-advice">
              <p class="popup-title">
                {{ "__candidatesConflictiveAdvice" | translate }}
              </p>
              <ul>
                <li>
                  <p>
                    <b>{{ "__invalidEmail" | translate }}:</b>
                    <span
                      [innerHTML]="'__invalidEmailAdvice' | translate"
                    ></span>
                  </p>
                </li>
                <li>
                  <p>
                    <b> {{ "__duplicateCandidate" | translate }}:</b>
                    <span
                      [innerHTML]="'__duplicateCandidateAdvice' | translate"
                    ></span>
                  </p>
                </li>
              </ul>
            </div>
          </span>
        </div>
        <div *ngIf="invalidEmailsSerialized.length > 0">
          <div
            class="candidates-list errors"
            *ngFor="let candidate of invalidEmailsSerialized; let i = index"
            [attr.data-index]="i"
          >
            <i class="material-icons candidate-icon">account_circle</i>
            <p>
              {{ candidate }}
            </p>
            <p class="advice-span">
              <i class="material-icons">error</i
              >{{ "__invalidEmail" | translate }}
            </p>
          </div>
        </div>
        <div *ngIf="existingEmailsSerialized.length > 0">
          <div
            class="candidates-list errors"
            *ngFor="let candidate of existingEmailsSerialized; let i = index"
            [attr.data-index]="i"
          >
            <i class="material-icons candidate-icon">account_circle</i>
            <p>
              {{ candidate }}
            </p>
            <p class="advice-span">
              <i class="material-icons">error</i
              >{{ "__duplicateCandidate" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-naked" (click)="closeModalAddCandidates()">
      {{ "__cancel" | translate }}
    </button>
    <button class="btn btn-main" (click)="addCandidatesList()">
      {{ "__add" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #generateResults>
  <div class="modal-header">
    <div class="modal-title">
      <h4 class="modal-title pull-left">
        {{ "__projectResults" | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ "__resultsGeneratedToCSV" | translate }}</p>
    <a #download hidden href="{{ resultsUrl }}" download>{{
      "__downloadFile" | translate
    }}</a>
  </div>
  <div class="modal-footer">
    <button class="btn btn-naked" (click)="modalRef.hide()">
      {{ "__cancel" | translate }}
    </button>
    <button class="btn btn-main" (click)="download.click()">
      {{ "__download" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #generateScores>
  <div class="modal-header">
    <div class="modal-title">
      <h4 class="modal-title pull-left">{{ "__projectScores" | translate }}</h4>
    </div>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ "__scoresGeneratedToCSV" | translate }}</p>
    <a #download hidden href="{{ scoresUrl }}" download
      >{{ "__downloadFile" | translate }}
    </a>
  </div>
  <div class="modal-footer">
    <button class="btn btn-naked" (click)="modalRef.hide()">
      {{ "__cancel" | translate }}
    </button>
    <button class="btn btn-main" (click)="download.click()">
      {{ "__download" | translate }}
    </button>
  </div>
</ng-template>
