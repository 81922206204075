import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { Candidate } from '../../classes/candidate';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PageChangedEvent, PaginationModule } from 'ngx-bootstrap/pagination';
import { Filter } from '../../utils';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CandidateStatusPipe } from '../../pipes/candidate-status.pipe';
import { User } from '../../classes/user';
import { AlertsService } from '../../services/alerts.service';
import { UserService } from '../../services/user.service';
import { ShareService } from '../../services/share.service';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UnslugifyPipe } from '../../pipes/unslugify.pipe';
import { CandidateDashboardComponent } from '../candidate-dashboard/candidate-dashboard.component';

@Component({
  selector: 'app-candidates-table',
  standalone: true,
  imports: [ CommonModule, TranslateModule, PaginationModule, MatTooltipModule, UnslugifyPipe, RouterModule, CandidateDashboardComponent ],
  providers: [ CandidateStatusPipe ],
  templateUrl: './candidates-table.component.html',
  styleUrls: [ './candidates-table.component.scss' ],
  animations: [
    trigger('openDetail', [
      state('true', style({
        transform: 'translateX(0%)'
      })),
      state('false', style({
        transform: 'translateX(100%)'
      })),
      transition('true => false', animate('200ms ease-in')),
      transition('false => true', animate('200ms ease-in'))
    ]),
    trigger('collapseTable', [
      state('true', style({
        width: '100%'
      })),
      state('false', style({
        width: '50%'
      })),
      transition('true => false', animate('200ms ease-in')),
      transition('false => true', animate('200ms ease-in'))
    ])
  ],
})
export class CandidatesTableComponent implements OnInit {

  @Output() displayCandidate = new EventEmitter<boolean>();

  private _project: number;
  @Input() set project(value: number) {
    this._project = value;
    this.setCandidates();
  }
  get project(): number {
    return this._project;
  }

  private _candidatesAdded: number;
  @Input() set added(value: number) {
    this._candidatesAdded = value;
    this.setCandidates();
  }
  get added(): number {
    return this._candidatesAdded;
  }

  loading: boolean = false;
  searchTerm = new Subject<EventTarget>();
  page = 0;
  search: any = "";
  statusFilter = "ALL";
  header_sorted = {
    header: "",
    order: 0
  };
  selectedRow: number;
  candidateSelected = false;
  candidateSelectedId: number;
  candidatesForActions: Candidate[] = [];
  candidates: Candidate[] = [];
  user: User;
  actual_filter: string;
  active_candidate: number[] = [];
  totalItems: number;
  paginationCondition: boolean = false;
  itemsPerPage: number = 20;

  modalRef: BsModalRef;
  candidateToDelete: number[] = [];

  constructor(
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private alertsService: AlertsService,
    public userService: UserService,
    private translateService: TranslateService,
    private _shareService: ShareService,
    private modalService: BsModalService,
    private candidateStatus: CandidateStatusPipe,
  ) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.subscribeCandidateParam();
    this.doSearch();
  }

  setCandidates() {
    this.loading = true;
    this.projectService.getCandidates(this._project).pipe().subscribe(
      response => {
        this.loading = false;
        this.candidates = response.body;
        this.totalItems = +response.headers.get('Count');
        this.paginationCondition = this.totalItems > this.itemsPerPage ? true : false;
      }
    );
  }

  subscribeCandidateParam() {

    this.activatedRoute.queryParamMap.subscribe(
      queryParam => {
        this.candidateSelectedId = +queryParam.get('candidate');
        this.candidateSelected = Boolean(this.candidateSelectedId);
        this.emitDisplayCandidate(this.candidateSelected);
        if (!this.candidateSelected) { this.selectedRow = null; }
        this.candidateSelectedId == null ? this._shareService.emitChange_report(false) : this._shareService.emitChange_report(true);

      }
    );
  }

  retrieve(): Observable<Candidate[]> {

    const parameters = [
      { name: "ordering", value: this._getSortedOrder() },
      { name: "search", value: this.search },
      { name: "status", value: this.statusFilter }
    ];

    return this.projectService.getFilteredCandidates(this._project, ...parameters).pipe();
  }

  doSearch(): void {

    this.searchTerm.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).pipe(switchMap(search => {
      this.search = search;
      const order = this._getSortedOrder();
      const parameters = [
        { name: "search", value: this.search },
        { name: "ordering", value: order },
        { name: "status", value: this.statusFilter }
      ];
      return this.retrieve();
    }))
      .subscribe(
        response => this.candidates = response
      );
  }

  filterByStatus(statusVal: any) {

    this.statusFilter = statusVal;
    this.actual_filter = statusVal;
    this.retrieve().subscribe(
      response => this.candidates = response
    );
  }

  sort(header: string) {

    this.header_sorted.header = header;
    this.header_sorted.order =
      this.header_sorted.order <= 0 ? this.header_sorted.order + 1 : -1;

    this.retrieve().subscribe(
      response => this.candidates = response
    );
  }

  _getSortedOrder(): string {
    let order = "";
    order += this.header_sorted.order < 0 ? "-" : "";
    order += this.header_sorted.header;
    return order;
  }

  sorted(header) {
    return this.header_sorted.header === header && this.header_sorted.order > 0;
  }

  sortedInverse(header) {
    return this.header_sorted.header === header && this.header_sorted.order < 0;
  }

  emitDisplayCandidate(status) {
    this.displayCandidate.emit(status);
  }

  toggleSelectionCandidate(event, candidate: Candidate) {
    const checked = event.target.checked;
    this.controlActiveCandidate(candidate.id);
    this.candidates.find(obj => obj == candidate).selected = checked;
  }

  selectAll(event) {
    const checked = event.target.checked;
    let checked_value = false;
    if (checked) {
      checked_value = true;
    }
    for (let index = 0; index < this.candidates.length; index++) {
      this.controlActiveCandidate(this.candidates[ index ].id);
      this.candidates[ index ].selected = checked_value;
    }
  }

  sendEmailByActionTable(type) {
    const candidateToFeedback = this.candidates
      .filter(candidate => candidate.selected)
      .map(candidate => candidate.id);

    const candidateSelected = this.candidates.filter(candidate => candidate.selected);
    const allEvaluated = !candidateSelected.some(candidate => {
      return this.candidateStatus.transform(candidate) !== 'Evaluated';
    });
    const someEvaluated = candidateSelected.some(candidate => {
      return this.candidateStatus.transform(candidate) === 'Evaluated';
    });

    if (candidateToFeedback.length > 0) {
      this.projectService.sendEmailByAction(this._project, candidateToFeedback, type).pipe().subscribe(
        response => {
          if (type === 'reminder' && allEvaluated) {
            this.alertsService.setAlert({ type: "warning", message: this.translateService.instant("__emailsNotSendByEvaluated") });
          } else if (type === 'reminder' && someEvaluated) {
            this.alertsService.setAlert({ type: "success", message: this.translateService.instant("__emailsCorrectSomeEvaluated") });
          } else {
            this.alertsService.setAlert({ type: "success", message: this.translateService.instant("__emailsCorrect") });
          }
        },
        error => {
          this.alertsService.setAlert({ type: "error", message: this.translateService.instant("__emailsNotSended") });
        }
      );
    }
  }

  deleteCandidatesByActionTables() {


    if (this.candidateToDelete.length > 0) {
      this.projectService.deleteCandidates(this._project, this.candidateToDelete).pipe().subscribe(
        response => {
          this.alertsService.setAlert({ type: "success", message: this.translateService.instant("__candidatesDeleted") });
          this.setCandidates();
          this.projectService.changeMessage('true');

        },
        error => {
          this.alertsService.setAlert({ type: "error", message: this.translateService.instant("__candidatesNotDeleted") });
        }
      );
    }
    this.modalRef.hide();
  }

  checkColor(candidate_id: number) {
    if (this.active_candidate.includes(candidate_id)) {
      return true;
    } else {
      return false;
    }
  }

  controlActiveCandidate(candidate_id: number) {
    if (this.active_candidate.includes(candidate_id)) {
      var pos = this.active_candidate.indexOf(candidate_id);
      this.active_candidate[ pos ] = null;
    } else {
      this.active_candidate.push(candidate_id);
    }
  }

  pageChanged(event: PageChangedEvent): void {
    const parameters = [ new Filter('page', `${Number(event.page)}`) ];
    this.loading = true;
    this.projectService.getCandidates(this._project, ...parameters).pipe().subscribe(
      response => {
        this.loading = false;
        this.candidates = response.body;
      }
    );
  }

  showModalToDelete(modal) {
    this.candidateToDelete = this.candidates
      .filter(candidate => candidate.selected)
      .map(candidate => candidate.id);

    if (this.candidateToDelete.length > 0) {
      this.modalRef = this.modalService.show(modal);
    }
  }

}

