import { Component, OnInit } from '@angular/core';
import { GroupService } from '../../services/group.service';
import { ActivatedRoute } from '@angular/router';
import { ShareService } from '../../services/share.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-client-integration',
  standalone: true,
  imports: [ CommonModule, TranslateModule, MatTooltipModule ],
  templateUrl: './client-integration.component.html',
  styleUrls: [ './client-integration.component.scss' ]
})
export class ClientIntegrationComponent implements OnInit {

  client_id: number;

  edit: { client: boolean, provider: boolean, } = {
    client: true,
    provider: true,
  };

  breadcrumbShow: boolean = false;
  clientUUID: string;
  providers: any;

  constructor(
    public groupService: GroupService,
    private activatedRoute: ActivatedRoute,
    private _shareService: ShareService
  ) { }

  ngOnInit() {
    this._shareService.setBreadcrumb('__integration');

    this.activatedRoute.parent.params.subscribe(params => {
      this.client_id = parseInt(params[ 'client' ]);
      this.groupService.getClient(this.client_id).subscribe(
        response => {
          this.clientUUID = response.uuid;
        });
      this.groupService.getProviders().subscribe(
        response => {
          this.providers = response.body;
        });
    });
  }

  copyTextToClipboard(element) {
    element.select();
    document.execCommand('copy');
    element.setSelectionRange(0, 0);
  }

}
