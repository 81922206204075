import { Instrument } from "./instrument";

export class Candidate {

    id: number;
    email: string;
    firstname?: string;
    lastname?: string;
    uuid: string;
    identifier: string;
    registration: string;
    start_tests: string;
    end_tests: string;
    get_status: string;
    results: { id: number, instrument: Instrument, done: boolean, token: string; }[];
    selected?: boolean = false;

}
