<div class="component-container component-container-report-open">
  <div class="analytics-wrapper">
    <div class="analytics-container">
      <div class="left-charts">
        <h3 class="analytics-container-header">
          {{ "__candidatesByStatus" | translate }}
        </h3>
        <div class="candidates-by-status-container analytics-element">
          <app-chart-pie
            *ngIf="candidatesByStatusData.length > 0"
            [data]="candidatesByStatusData"
            [series]="candidatesByStatusSeries"
            [height]="220"
          ></app-chart-pie>
        </div>
      </div>

      <div class="right-charts">
        <div *ngIf="analytics" class="average-test-time-container">
          <h3 class="analytics-container-header">
            {{ "__avgTime" | translate }}
          </h3>
          <div class="analytics-container-content analytics-element">
            <div class="time-box">
              {{ analytics.average_time_tests | verboseTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
