import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HighchartService {

  colors = [ '#1C3FFD', '#1C3FFD', '#1C3FFD' ];

  constructor() { }

  getDataFromArray(name, data, showInLegend = false) {
    return [ { name: name, data: data, pointPlacement: 'on', color: this.colors[ 0 ], showInLegend: showInLegend } ];
  }

  getDataFromDictOfArrays(name, data, showInLegend = false) {
    return Object.keys(data).map((value, index) =>
      ({ name: value, data: data[ value ], pointPlacement: 'on', color: this.colors[ index ], showInLegend: showInLegend }));
  }

  isDictionary(obj) {
    return typeof obj === 'object' && obj !== null && !(obj instanceof Array) && !(obj instanceof Date);
  }
}
