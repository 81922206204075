<div class="dashboard-candidate__container">
    <div class="loader" *ngIf="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>

    <div class="dashboard-candidate__header">
      <section class="candidate__personal-data">
        <div class="candidate__data-content">
          <h4>{{report?.email}}</h4>
          <span class="status-tag" [ngClass]="status | lowercase"><i class="material-icons">fiber_manual_record</i>{{status}}</span>
          <p>{{report?.uuid}}</p>
        </div>
        <div class="section__element">
          <button (click)="printPDF()" class="btn-print">
            <i class="fa fa-share-square"></i> Print
          </button>
        </div>
      </section>
    </div>

    <div class="dashboard-candidate__content">
      <section class="candidate-section client-info__content" *ngIf="report">

        <div class="section__element">
          <div class="loader" *ngIf="waitingReport">
            <i class="fas fa-spinner fa-pulse"></i>
          </div>
        </div>

      </section>

      <section class="candidate-section client-info__content" *ngIf="report">
        <div class="section__element_results">
          <app-candidate-results *ngIf="!waitingReport && thereIsReport" [mapper]="report.instrument.driver.identifier" [report]="report.report.extended_report"></app-candidate-results>
        </div>

      </section>

      <div class="hint-block--purple" *ngIf="!report || report.report.length === 0">
       {{'__noResultsCandidate' | translate }}
      </div>
    </div>

  </div>

