import { Component, OnInit } from '@angular/core';
import { User } from '../../classes/user';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { patternValidator, matchPassword } from '../../utils';
import { AlertsService } from '../../services/alerts.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BsDropdownModule
  ],
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnInit {

  user: User;
  modalRef: BsModalRef;
  form: FormGroup = new FormGroup({
    currentPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('',
      [
        Validators.minLength(10),
        patternValidator(/\d/, { hasNumber: true }),
        patternValidator(/[A-Z]/, { hasUppercase: true }),
        patternValidator(/[a-z]/, { hasLowercase: true }),
        patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSymbol: true }),
        Validators.required
      ]
    ),
    confirmPassword: new FormControl('', Validators.required)
  },
    {
      validators: matchPassword('newPassword', 'confirmPassword'),
    }
  );

  invalidPassReset = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private modalService: BsModalService,
    private alertService: AlertsService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.user = this.userService.getUser();
  }

  showModal(modal) {
    this.modalRef = this.modalService.show(modal);
  }

  logout() {
    this.userService.logout();
    this.router.navigate([ 'login' ]);
  }

  handlePasswordError(error: any) {
    if (error.error
      && error.error.non_field_errors
      && error.error.non_field_errors.length
      && error.error.non_field_errors[ 0 ] === 'Invalid password') {
      this.invalidPassReset = true;
    } else {
      this.alertService.setAlert({ type: 'error', message: error });
      this.invalidPassReset = false;
    }
  }

  setPassword() {
    this.userService.newPassword(this.currentPassword.value, this.newPassword.value)
      .subscribe(
        response => this.alertService.setAlert({ type: 'success', message: this.translateService.instant('__changePasswordSuccessfully') }),
        error => this.handlePasswordError(error)
      );
    this.closeModal();
  }

  closeModal() {
    this.form.setValue({ currentPassword: '', newPassword: '', confirmPassword: '' });
    this.modalRef.hide();
  }


  get newPassword() { return this.form.get('newPassword'); }
  get confirmPassword() { return this.form.get('confirmPassword'); }
  get currentPassword() { return this.form.get('currentPassword'); }

}
