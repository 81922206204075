import { AlertsService } from './alerts.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, empty } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Settings } from '../classes/settings';
import { inject, Injectable, Injector } from "@angular/core";
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class Jwtinterceptor implements HttpInterceptor {

  translateService: TranslateService = inject(TranslateService);
  constructor(private router: Router, private inj: Injector) { }

  intercept(req: HttpRequest<any>,
    handler: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem("jwt");
    const isTranslationUrl = req.url.includes(Settings.TRANSLATIONS_URL);
    if (idToken && !isTranslationUrl) {
      const cloned = req.clone({
        "headers": req.headers.set("Authorization", "JWT " + idToken)
      });

      return this.handlerError(handler.handle(cloned));
    } else {
      return this.handlerError(handler.handle(req));
    }
  }


  handlerError(obs: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> {
    return obs.pipe(catchError(e => {
      if (this.router.url !== '/login' && e.status === 401) {
        this.inj.get(UserService).logout();
        this.inj.get(AlertsService).setAlert({ type: 'warning', message: this.translateService.instant("__authenticateAgain") });
        this.router.navigate([ '/login' ]);
        return empty();
      }
      return throwError(e);
    }));
  }

}
