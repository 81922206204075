import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chart-versus',
  standalone: true,
  imports: [ CommonModule ],
  templateUrl: './chart-versus.component.html',
  styleUrls: [ './chart-versus.component.scss' ]
})
export class ChartVersusComponent implements OnInit {

  @Input() data: number[];
  @Input() labels: string[];
  @Input() name: string;
  @Input() max: number;

  colors = [ '#786ce3', '#efc351' ];

  constructor() { }

  ngOnInit() {
    this.setMaxValue();
  }

  setMaxValue() {
    this.max = this.max ? this.max : Math.max.apply(null, this.data);
  }

}
