import { Component, OnInit } from '@angular/core';
import { Client } from '../../classes/client';
import { GroupService } from '../../services/group.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../services/alerts.service';
import { ShareService } from '../../services/share.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { UploadImageComponent } from '../upload-image/upload-image.component';

@Component({
  selector: 'app-client-appearance',
  standalone: true,
  imports: [ CommonModule, TranslateModule, ColorPickerModule, UploadImageComponent ],
  templateUrl: './client-appearance.component.html',
  styleUrls: [ './client-appearance.component.scss' ]
})
export class ClientAppearanceComponent implements OnInit {

  appearance: Client[ 'appearance' ];
  _logo: string;

  client_id: number;

  edit: { description: boolean, graphics: boolean, media: boolean, } = {
    description: true,
    graphics: true,
    media: true,
  };

  breadcrumbShow: boolean = false;

  constructor(
    public groupService: GroupService,
    private activatedRoute: ActivatedRoute,
    private alertsService: AlertsService,
    private translateService: TranslateService,
    private _shareService: ShareService
  ) { }

  ngOnInit() {
    this._shareService.setBreadcrumb('__appearance');

    this.activatedRoute.parent.params.subscribe(params => {
      this.client_id = parseInt(params[ 'client' ]);
      this.groupService.getClient(this.client_id).pipe().subscribe(
        response => {
          this.appearance = Object.assign(new Client().appearance, response.appearance);
        });
    });
  }

  save() {
    this.groupService.updateAppearance(this.client_id, this.appearance).pipe().subscribe(
      response => {
        this.alertsService.setAlert({ type: "success", message: this.translateService.instant("__appearanceUpdated") });
      }, error => this.alertsService.setAlert({ type: "error", message: this.translateService.instant("__appearanceNotUpdate") })
    );
  }

  getElement(element_id) {
    return document.getElementById(element_id);
  }

}
