import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Settings } from './classes/settings';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './components/alert/alert.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [ RouterModule, TranslateModule, AlertComponent ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'disney-front-v2';

  translateService: TranslateService = inject(TranslateService);

  constructor() {
    this.translateService.setDefaultLang(Settings.DEFAULT_LANGUAGE);
    this.translateService.use(this.getNavigatorLanguage());
  }

  private getNavigatorLanguage(): string {
    const availableLanguages = [ 'en', 'es' ];
    const userLanguage = availableLanguages.find(lang => navigator.language.includes(lang)) || Settings.DEFAULT_LANGUAGE;
    return userLanguage;
  }
}
