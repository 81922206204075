<div class="client-appearance-builder">
  <div class="client-appearance-builder__content">
    <div class="client-appearance-builder__step">
      <div class="client-appearance-builder__header">
        <div class="text-container">
          <h2>{{ '__clientIntegration' | translate }}</h2>
          <p>{{ '__clientIntegrationDesc' | translate }}</p>
        </div>
      </div>

      <div class="client-appearance-container">
        <section class="client-info">
          <div class="section-info__header" (click)="edit.client = !edit.client">
            <h5 class="section__header">
              <b>{{ '__clientInformation' | translate }}.</b>
            </h5>
            <i class="material-icons" [innerHtml]="edit.client ? 'expand_less' : 'expand_more'"></i>
          </div>

          <div class="client-info__content" *ngIf="edit.client">
            <div class="specific-info">
              <div class="specific-info__row">
                <div class="specific-info__col">
                  <div class="specific-info__content">
                    <table style="width: 100%">
                      <tr>
                        <th class="table-title">{{ '__clientUuid' | translate }}</th>
                        <th>
                          <b class="specific-info__title">{{ clientUUID }}</b>
                          <input class="hide-url" #clientUuid [value]="clientUUID" [size]="45" readonly />
                        </th>
                        <th>
                          <i
                            class="material-icons pointer"
                            matTooltip="Copy link"
                            [matTooltipPosition]="'below'"
                            (click)="copyTextToClipboard(clientUuid)"
                            >file_copy</i
                          >
                        </th>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="client-info">
          <div class="section-info__header" (click)="edit.provider = !edit.provider">
            <h5 class="section__header">
              <b>{{ '__providerInformation' | translate }}.</b>
            </h5>
            <i class="material-icons" [innerHtml]="edit.provider ? 'expand_less' : 'expand_more'"></i>
          </div>

          <div class="client-info__content" *ngIf="edit.provider">
            <div class="specific-info">
              <div class="specific-info__row">
                <div class="specific-info__col">
                  <table style="width: 100%">
                    <tr *ngFor="let provider of providers">
                      <th class="table-title">{{ provider.name }}</th>
                      <th>
                        <b class="specific-info__title">{{ provider.uuid }}</b>
                        <input class="hide-url" #providerUuid [value]="provider.uuid" [size]="45" readonly />
                      </th>
                      <th>
                        <i
                          class="material-icons pointer"
                          matTooltip="Copy link"
                          [matTooltipPosition]="'below'"
                          (click)="copyTextToClipboard(providerUuid)"
                          >file_copy</i
                        >
                      </th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
