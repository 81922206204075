<div class="component-container">
  <div
    class="table-wrapper"
    [@collapseTable]="!candidateSelected"
    [ngClass]="{ collapse: candidateSelected }"
  >
    <div class="table-container">
      <div class="table-actions">
        <div>
          <a
            class="filter"
            [ngClass]="{ 'filter-active': this.actual_filter === 'ALL' }"
            (click)="filterByStatus('ALL')"
            >{{ "__all" | translate }}</a
          >
          <a
            class="filter"
            [ngClass]="{ 'filter-active': this.actual_filter === 'INVITED' }"
            (click)="filterByStatus('INVITED')"
            >{{ "__invited" | translate }}</a
          >
          <a
            class="filter"
            [ngClass]="{
              'filter-active': this.actual_filter === 'IN_PROGRESS'
            }"
            (click)="filterByStatus('IN_PROGRESS')"
            >{{ "__inProgress" | translate }}</a
          >
          <a
            class="filter"
            [ngClass]="{ 'filter-active': this.actual_filter === 'EVALUATED' }"
            (click)="filterByStatus('EVALUATED')"
            >{{ "__evaluated" | translate }}</a
          >
        </div>

        <div dropdown class="table-actions-candidates">
          <a
            class="bubble action-size"
            matTooltipClass="popup-appearance"
            [matTooltipPosition]="'above'"
            (click)="sendEmailByActionTable('feedback')"
            mat-raised-button
            matTooltip=" {{ '__sendFb' | translate }}"
          >
            <i class="far fa-envelope fa-lg"></i>
          </a>

          <a
            class="bubble action-size"
            matTooltipClass="popup-appearance"
            [matTooltipPosition]="'above'"
            (click)="sendEmailByActionTable('reminder')"
            mat-raised-button
            matTooltip=" {{ '__sendReminder' | translate }}"
          >
            <i class="fas fa-exclamation fa-lg"></i>
          </a>

          <a
            *ngIf="user.isPartner() || user.isAdmin()"
            class="bubble action-size"
            matTooltipClass="popup-appearance"
            [matTooltipPosition]="'above'"
            (click)="showModalToDelete(modalDeleteCandidate)"
            mat-raised-button
            matTooltip=" {{ '__deleteCandidates' | translate }}"
          >
            <i class="far fa-trash-alt fa-lg"></i>
          </a>
        </div>
        <div class="input-search-table">
          <i class="fas fa-search fa-sm"></i>
          <input
            class="searchZone"
            (keyup)="searchTerm.next($any($event).currentTarget.value)"
            type="text"
            placeholder="{{ '__search' | translate }}"
            #searchCandidate
          />
        </div>
      </div>

      <div class="candidates-table-header">
        <table>
          <tr class="table-header">
            <th width="5%">
              <input
                class="checkStyle-header"
                type="checkbox"
                (click)="selectAll($event)"
              />
            </th>

            <th (click)="sort('identifier')" width="20%">
              <div class="table__header">
                <span class="table__header__title">{{
                  "__extId" | translate
                }}</span>
                <span class="table__header__sort">
                  <div *ngIf="sortedInverse('identifier')">
                    <i class="fas fa-caret-up"></i>
                  </div>
                  <div *ngIf="sorted('identifier')">
                    <i class="fas fa-caret-down"></i>
                  </div>
                </span>
              </div>
            </th>
            <th (click)="sort('email')" width="25%">
              <div class="table__header">
                <span class="table__header__title">{{
                  "__email" | translate
                }}</span>
                <span class="table__header__sort">
                  <div *ngIf="sortedInverse('email')">
                    <i class="fas fa-caret-up"></i>
                  </div>
                  <div *ngIf="sorted('email')">
                    <i class="fas fa-caret-down"></i>
                  </div>
                </span>
              </div>
            </th>
            <th width="20%">
              <div class="table__header">
                <span class="table__header__title">{{
                  "__firstName" | translate
                }}</span>
              </div>
            </th>
            <th width="20%">
              <div class="table__header">
                <span class="table__header__title"
                  >{{ "__lastName" | translate }}
                </span>
              </div>
            </th>

            <th width="10%">
              <div class="table__header">
                <span class="table__header__title">{{
                  "__status" | translate
                }}</span>
              </div>
            </th>
          </tr>
        </table>
      </div>
      <div class="candidates-table-header">
        <table>
          <tr
            class="candidate-row"
            [ngClass]="[
              checkColor(candidate.id) || candidateSelectedId == candidate.id
                ? 'candidate-row-active'
                : ''
            ]"
            *ngFor="let candidate of candidates"
          >
            <td width="5%">
              <input
                class="checkStyle"
                type="checkbox"
                (click)="toggleSelectionCandidate($event, candidate)"
                [checked]="candidate.selected"
              />
            </td>

            <td
              width="20%"
              (click)="selectedRow = candidate.id"
              [ngClass]="{ selected: selectedRow === candidate.id }"
              [routerLink]="['.']"
              [queryParams]="{ candidate: candidate?.id }"
            >
              <span class="table__cell">{{ candidate.identifier }} </span>
            </td>
            <td
              width="25%"
              (click)="selectedRow = candidate.id"
              [ngClass]="{ selected: selectedRow === candidate.id }"
              [routerLink]="['.']"
              [queryParams]="{ candidate: candidate?.id }"
            >
              <span class="table__cell">{{ candidate.email }} </span>
            </td>

            <td
              width="20%"
              (click)="selectedRow = candidate.id"
              [ngClass]="{ selected: selectedRow === candidate.id }"
              [routerLink]="['.']"
              [queryParams]="{ candidate: candidate?.id }"
            >
              <span class="table__cell">{{ candidate.firstname }} </span>
            </td>
            <td
              width="20%"
              (click)="selectedRow = candidate.id"
              [ngClass]="{ selected: selectedRow === candidate.id }"
              [routerLink]="['.']"
              [queryParams]="{ candidate: candidate?.id }"
            >
              <span class="table__cell">{{ candidate.lastname }} </span>
            </td>
            <td width="10%">
              <span
                class="table__cell"
                [ngStyle]="{
                  color:
                    candidate.get_status == 'evaluated'
                      ? '#558ff3'
                      : candidate.get_status == 'invited'
                      ? '#163671'
                      : '#eac97b'
                }"
              >
                {{ candidate.get_status | unslugify }}
              </span>
            </td>
          </tr>
        </table>

        <div *ngIf="paginationCondition" class="page-table-buttons">
          <pagination
            pageBtnClass="btn"
            [itemsPerPage]="itemsPerPage"
            [maxSize]="4"
            [boundaryLinks]="true"
            (pageChanged)="pageChanged($event)"
            [totalItems]="totalItems"
            [directionLinks]="false"
          ></pagination>
        </div>
      </div>

      <div class="hint-block--purple" *ngIf="!candidates[0]">
        {{ "__noCandidatesProject" | translate }}
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="candidateSelected"
  [ngClass]="{ 'candidate-selected': candidateSelected }"
>
  <div class="candidate-detail__container" [@openDetail]="candidateSelected">
    <app-candidate-dashboard
      *ngIf="candidateSelected"
      [projectId]="project"
      [candidateId]="selectedRow"
    >
    </app-candidate-dashboard>
  </div>
</div>

<ng-template #modalDeleteCandidate>
  <div class="modal-header">
    <div class="modal-title">
      <h4 class="modal-title pull-left">
        {{ "__deleteCandidates" | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-description">
      {{ "__deleteCandidatesSelected" | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-naked" (click)="modalRef.hide()">
      {{ "__cancel" | translate }}
    </button>
    <button class="btn btn-main" (click)="deleteCandidatesByActionTables()">
      {{ "__delete" | translate }}
    </button>
  </div>
</ng-template>
