import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AlertsService } from '../../services/alerts.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [ CommonModule ],
  templateUrl: './alert.component.html',
  styleUrls: [ './alert.component.scss' ],
  animations: [
    trigger('slide', [
      state('true', style({
        transform: 'translateX(0%)',
        opacity: '1',
        display: 'flex'
      })),
      state('false', style({
        transform: 'translateX(100%)',
        opacity: '0',
        display: 'none'
      })),
      transition('true => false', animate('300ms ease-in')),
      transition('false => true', animate('300ms ease-in'))
    ])
  ]
})
export class AlertComponent implements OnInit {

  showAlert: boolean = false;
  autoClose;
  private _type: string = '';
  private _message: string = '';

  set type(type: string) {
    if (type) {
      clearTimeout(this.autoClose);
      this.showAlert = true;
      this._type = type;
      if (type !== 'error') {
        this.autoClose = setTimeout(this.hideAlert.bind(this), 3000);
      }
    }
  }
  get type(): string {
    return this._type;
  }

  set message(message: string) {
    if (message) {
      this._message = message;
    }
  }
  get message(): string {
    return this._message;
  }

  constructor(private alertService: AlertsService) { }

  ngOnInit() {
    this.alertService.showAlert$.subscribe(alertData => this.setAlertData(alertData));
  }

  hideAlert() {
    this.showAlert = false;
    this.type = '';
    this.message = '';
  }

  setAlertData(alertData) {
    this.type = alertData.type;
    this.message = alertData.message;
  }

}
