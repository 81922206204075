import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { HighchartService } from '../../../services/highchart.service';
import { ChartRenderComponent } from '../chart-render/chart-render.component';
declare const Highcharts: any;

@Component({
  selector: 'app-chart-horizontal-bar',
  standalone: true,
  imports: [ ChartRenderComponent ],
  templateUrl: './chart-horizontal-bar.component.html',
  styleUrls: [ './chart-horizontal-bar.component.scss' ]
})
export class ChartHorizontalBarComponent implements OnInit {

  _arrayData: number[];
  _dictOfArraysData: any; // Example: {candidate: [3, 5, 7], profile_reference: [4, 6, 6]}
  @Input() set data(value: any) {
    if (value instanceof Array) {
      this._arrayData = value;
      if (this._arrayData[ this._arrayData.length - 1 ] != 0) {
        this._arrayData.push(0);
      }
      if (this._arrayData[ 0 ] != 0) {
        this._arrayData.unshift(0);
      }
    } else {
      if (this.highchartsService.isDictionary(value)) {
        this._dictOfArraysData = value;
        Object.keys(this._dictOfArraysData).forEach(key => {
          this._dictOfArraysData[ key ];
          this._dictOfArraysData[ key ].push(0);
          this._dictOfArraysData[ key ].unshift(0);
        });
      }
    }
  }

  get data(): any {
    return this._arrayData ? this._arrayData : this._dictOfArraysData;
  }
  @Input() labels: string[];
  @Input() name: string;
  @Input() scale: number;

  config = {
    chart: { type: 'column' },
    plotOptions: {
      series: {
        borderRadius: 10
      }
    }
  };

  constructor(
    private highchartsService: HighchartService,
  ) { }

  ngOnInit() {
    if (this.labels[ this.labels.length - 1 ] != '') {
      this.labels.push('');
    }
    if (this.labels[ 0 ] != '') {
      this.labels.unshift('');
    }
  }

}
