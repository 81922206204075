<div class="report-wrapper">
  <div *ngFor="let item of report; let i = index;">
    <div class="report_item" *ngIf="item.show">
      <div class="item_title-wrapper">
        <h4 class="item_title">{{"__" + item.title  | titlecase | slugify | translate}}</h4>
        <button (click)="toggleResult(i)">
          <i class="material-icons" *ngIf="!item.hidden">keyboard_arrow_up</i>
          <i class="material-icons" *ngIf="item.hidden">keyboard_arrow_down</i>
        </button>
      </div>
      <div
        *ngIf="!item.hidden && item.text.length > 0 && item.title !== 'weakness strengths' && item.title !== 'video-questions'"
        class="item_content">
        <p class="text-element" *ngFor="let text of item.text">
          <span
            *ngIf="text.content!=='' && text.variable !=='' || (text.question != null && text.question != '') && text.content!=='_' ">
            <span
              *ngIf="text.variable!=='Social desirability' && text.content !== '' && (text.question == null || text.question == '')"
              class="content-variable">{{"__" + text.variable | slugify | translate}}</span><br>
            <span *ngIf="(text.question != null && text.question != '')"
              class="content-variable">{{text.question}}</span> <br>
            <span *ngIf="text.content!=='' && text.content!=='_'" class="content-text">{{text.content}}</span>
          </span>

        </p>
      </div>

      <div *ngIf="!item.hidden && item.text.length > 0 && item.type === 'VIDEOASK'">
        <div *ngFor="let question of item.text">
          <div class="video-card">
            <div class="video-card-content">
              <div [ngSwitch]="question.type">

                <div *ngSwitchCase="'video'" class="video-wrapper">
                  <div class="question-video-viewport">
                    <a></a>
                    <p>{{question.question_text}}</p>
                    <video [poster]="question.question_thumbnail" controls>
                      <source [src]="question.question_video" type="video/mp4">
                    </video>
                  </div>
                  <div class="question-video-viewport">
                    <a></a>
                    <p>{{question.answer_text}}</p>
                    <video [poster]="question.answer_thumbnail" controls>
                      <source [src]="question.answer_video" type="video/mp4">
                    </video>
                  </div>
                </div>

                <div *ngSwitchCase="'audio'" class="video-wrapper">
                  <div class="question-audio-viewport">
                    <a></a>
                    <p>{{question.question_text}}</p>
                    <video [poster]="question.question_thumbnail" controls>
                      <source [src]="question.question_video" type="video/mp4">
                    </video>
                  </div>
                  <div class="question-audio-viewport">
                    <h6>{{question.answer_text}}</h6>
                    <audio controls>
                      <source [src]="question.answer_audio" type="audio/mpeg">
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                </div>

                <div *ngSwitchDefault class="video-wrapper">
                  <div class="question-text-viewport">
                    <a></a>
                    <p>{{question.question_text}}</p>
                    <video [poster]="question.question_thumbnail" controls>
                      <source [src]="question.question_video" type="video/mp4">
                    </video>
                  </div>
                  <div class="question-text-viewport">
                    <h6>{{question.answer_text}}</h6>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>
      </div>
      <!-- Weakness & strengths -->
      <div *ngIf="!item.hidden && item.text.length > 0 && item.title === 'weakness strengths'"
        class="weakness-strengths_content">

        <div class="table-w">
          <div class="table-row">
            <div class="padding-first-row"></div>
            <div class="padding-first-row">{{'__habits' | translate}}</div>
            <div class="padding-first-row">{{'__styles' | translate}}</div>
            <div class="padding-first-row">{{'__knowledge' | translate}}</div>
          </div>

          <div class="table-row">
            <div class="col padding-side"><span class="content-title"> {{'__weakness' | translate }}</span></div>
            <div class="col padding-side-color">
              <p *ngFor="let text of item.text"><span
                  *ngIf="text.content === 'w' && text.group === 'HABITS'">{{"__" + text.variable | slugify | translate}}</span>
              </p>
            </div>
            <div class="col padding-side-color">
              <p *ngFor="let text of item.text"><span
                  *ngIf="text.content === 'w' && text.group === 'STYLES'">{{"__" + text.variable | slugify | translate}}</span>
              </p>
            </div>
            <div class="col padding-side-color">
              <p *ngFor="let text of item.text"><span
                  *ngIf="text.content === 'w' && text.group === 'KNOWLEDGE'">{{"__" + text.variable | slugify | translate}}</span>
              </p>
            </div>
          </div>

          <div class="table-row">
            <div class="col padding-side"><span class="content-title"> {{'__strengths' | translate }}</span></div>
            <div class="col padding-side-color">
              <p *ngFor="let text of item.text"><span
                  *ngIf="text.content === 's' && text.group === 'HABITS'">{{"__" + text.variable | slugify | translate}}</span>
              </p>
            </div>
            <div class="col padding-side-color">
              <p *ngFor="let text of item.text"><span
                  *ngIf="text.content === 's' && text.group === 'STYLES'">{{"__" + text.variable | slugify | translate}}</span>
              </p>
            </div>
            <div class="col padding-side-color">
              <p *ngFor="let text of item.text"><span
                  *ngIf="text.content === 's' && text.group === 'KNOWLEDGE'">{{"__" + text.variable | slugify | translate}}</span>
              </p>
            </div>
          </div>

        </div>


      </div>
      <!-- No Digital Hero widgets -->
      <div
        *ngIf="!item.hidden && item.widget && item.widget.length > 0 && (mapper!=='digital_hero' && mapper!=='building_mia')"
        class="item_chart">
        <div *ngFor="let widget of item.widget">
          <div *ngIf="widget.data.length > 0" class="chart-wrapper" [ngSwitch]="widget.type">
            <app-chart-radar *ngSwitchCase="'radar'" [name]="'__' + widget.name | slugify | translate"
              [data]="widget.data" [labels]="widget.labels" [scale]="widget.scale"></app-chart-radar>
            <app-chart-horizontal-bar *ngSwitchCase="'horizontal'" [name]="'__' + widget.name | slugify | translate"
              [data]="widget.data" [labels]="widget.labels" [scale]="widget.scale"></app-chart-horizontal-bar>

            <!-- <app-chart-versus *ngSwitchCase="'versus'" [name]="'__' + widget.name | slugify | translate"
              [data]="widget.data" [labels]="widget.labels" [max]="widget.extra_fields.max_value"></app-chart-versus>-->

            <h4 *ngSwitchCase="'versus'" class="chart-header">
              {{'__' + widget.name | slugify  | translate }}</h4>
            <div *ngSwitchCase="'versus'" class="reminded-candidates-container analytics-element">
              <app-chart-progress-bar *ngSwitchCase="'versus'" class="progress-bar-appearance" [data]="widget.data"
                [max]="widget.extra_fields.max_value">
              </app-chart-progress-bar>
            </div>

          </div>
        </div>
      </div>
      <!-- Digital Hero widgets -->
      <div *ngIf="!item.hidden && item.widget && (mapper==='digital_hero' || mapper==='building_mia')"
        class="item_chart digital_hero-wrapper">
        <div class="digital_hero-colum">
          <div class="digital_hero-chart" *ngFor="let widget of item.widget.first">
            <div *ngIf="widget.data.length > 0" [ngClass]="'chart-'+widget.type+'-wrapper'" class="chart-wrapper"
              [ngSwitch]="widget.type">
              <app-chart-radar *ngSwitchCase="'radar'" [name]="'__' + widget.name | slugify | translate"
                [data]="widget.data" [labels]="widget.labels" [scale]="widget.scale"></app-chart-radar>
              <app-chart-versus *ngSwitchCase="'versus'" [name]="'__' + widget.name | slugify | translate"
                [data]="widget.data" [labels]="widget.labels" [max]="widget.extra_fields.max_value">
              </app-chart-versus>
            </div>
          </div>
        </div>
        <div class="digital_hero-colum">
          <div class="digital_hero-chart" *ngFor="let widget of item.widget.second">
            <div *ngIf="widget.data.length > 0" [ngClass]="'chart-'+widget.type+'-wrapper'" class="chart-wrapper"
              [ngSwitch]="widget.type">
              <app-chart-horizontal-bar *ngSwitchCase="'horizontal'" [name]="widget.name" [data]="widget.data"
                [labels]="widget.labels" [scale]="widget.scale"></app-chart-horizontal-bar>
              <app-chart-progress-bar *ngSwitchCase="'progress'" [name]="widget.name" [data]="widget.data"
                [max]="widget.extra_fields.max_value"></app-chart-progress-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>