import { Component, OnInit } from '@angular/core';
import { InstrumentService } from '../../services/instrument.service';
import { Instrument } from '../../classes/instrument';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ShareService } from '../../services/share.service';
import { AlertsService } from '../../services/alerts.service';
import { CommonModule } from '@angular/common';
import { DndModule } from 'ngx-drag-drop';

@Component({
  selector: 'app-client-add-instrument',
  standalone: true,
  imports: [ CommonModule, TranslateModule, DndModule ],
  templateUrl: './client-add-instrument.component.html',
  styleUrls: [ './client-add-instrument.component.scss' ]
})
export class ClientAddInstrumentComponent implements OnInit {

  breadcrumbShow: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private instrumentService: InstrumentService,
    private alertService: AlertsService,
    private translateService: TranslateService,
    private _shareService: ShareService
  ) { }

  client_id: number;
  availableInstruments: Instrument[] = [];
  selectedInstruments = [];
  clientInstruments: Instrument[] = [];

  ngOnInit() {
    // Use endpoint to get possible instruments to add   

    this.activatedRoute.parent.params.subscribe(params => {
      this.client_id = parseInt(params[ 'client' ]);
      this.getAvailableInstruments();
      this.setClientInstruments();
    });

    this._shareService.setBreadcrumb('__instruments');
  }

  getAvailableInstruments() {
    this.instrumentService.getAvailableInstruments(this.client_id.toString()).pipe().subscribe(
      response => this.availableInstruments = response
    );
  }

  onDraggableMoved(event: DragEvent, instrument: any, index: number) {
    this.selectedInstruments.push(instrument);
    this.availableInstruments.splice(index, 1);
  }

  addInstruments() {
    const instruments = this.selectedInstruments.map(instrument => instrument.id);

    this.instrumentService.addInstrumentsToClient(this.client_id.toString(), instruments).pipe().subscribe(response => {
      this.alertService.setAlert({ type: 'success', message: this.translateService.instant("__instrumentsAdded") });
      this.resetInstruments();
    },
      error => this.alertService.setAlert({ type: 'error', message: this.translateService.instant("__instrumentsNotAdded") })
    );
  }

  resetInstruments() {
    this.getAvailableInstruments();
    this.selectedInstruments = [];
  }

  setClientInstruments() {
    this.instrumentService.getInstrumentsByClient(this.client_id.toString()).pipe().subscribe(
      response => this.clientInstruments = response
    );
  }
}
