import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService, LoginGuardService } from './guards/auth-guard.service';
import { User } from './classes/user';
import { UserService } from './services/user.service';
import { ProjectComponent } from './components/project/project.component';
import { CreateProjectComponent } from './components/create-project/create-project.component';
import { ClientAddInstrumentComponent } from './components/client-add-instrument/client-add-instrument.component';
import { ClientAppearanceComponent } from './components/client-appearance/client-appearance.component';
import { ClientIntegrationComponent } from './components/client-integration/client-integration.component';
import { PublicReportComponent } from './components/public-report/public-report.component';

export const routeConfig: Routes = [
  {
    path: 'app',
    loadComponent: () =>
      import('./components/root/root.component')
        .then(m => m.RootComponent),
    children: [
      {
        loadComponent: () =>
          import('./components/admin/admin.component')
            .then(m => m.AdminComponent),
        path: 'admin',
        canActivate: [ AuthGuardService ],
        data: {
          permission: User.Admin
        }
      },
      {
        loadComponent: () =>
          import('./components/instrument-list/instrument-list.component')
            .then(m => m.InstrumentListComponent),
        path: 'instruments',
        canActivate: [ AuthGuardService ],
        data: {
          permission: User.Admin
        }
      },
      {
        loadComponent: () =>
          import('./components/partner/partner.component')
            .then(m => m.PartnerComponent),
        path: 'partner/:partner',
        canActivate: [ AuthGuardService ],
        data: {
          permission: User.Partner,
          group: User.Partner
        }
      },
      {
        loadComponent: () =>
          import('./components/client/client.component')
            .then(m => m.ClientComponent),
        path: 'client/:client',
        canActivate: [ AuthGuardService ],
        data: {
          permission: User.Client,
          group: User.Partner
        },
        children: [
          {
            path: 'project/:project',
            component: ProjectComponent
          },
          {
            path: 'instruments',
            component: ClientAddInstrumentComponent
          },
          {
            path: 'settings',
            component: ClientAppearanceComponent
          },
          {
            path: 'integration',
            component: ClientIntegrationComponent
          },
          {
            path: 'project',
            component: CreateProjectComponent
          }
        ]
      }
    ]
  },
  {
    path: 'public-report/:token',
    component: PublicReportComponent
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./components/login/login.component')
        .then(m => m.LoginComponent),
    canActivate: [ LoginGuardService ],
    data: { state: 'login' }
  },
  {
    path: 'recover',
    component: LoginComponent,
    canActivate: [ LoginGuardService ],
    data: { state: 'recover' }
  },
  {
    path: 'reset/:uid/:token',
    component: LoginComponent,
    canActivate: [ LoginGuardService ],
    data: { state: 'reset' }
  },
  {
    path: '',
    component: LoginComponent,
    data: { state: 'login' }
  }
];

export default routeConfig;