import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Settings } from '../classes/settings';
import { Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  reportComponent: Observable<any>;
  sidebarComponent: Observable<any>;

  candidatesUpdate: BehaviorSubject<any>;

  constructor(
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.reportComponent = this.activatedRoute.parent.queryParams;
    this.sidebarComponent = this.activatedRoute.parent.queryParams;
  }

  getReport(id: number): Observable<any> {
    const url = Settings.RESULTS_REPORT_URL.replace('{id}', id.toString());
    return this.httpClient.get<any>(url);
  }

  getPublicReport(token: string): Observable<any> {
    const url = Settings.PUBLIC_RESULTS_REPORT_URL.replace('{token}', token.toString());
    return this.httpClient.get<any>(url);
  }


}
