import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ChartRadarComponent } from '../chart/chart-radar/chart-radar.component';
import { ChartHorizontalBarComponent } from '../chart/chart-horizontal-bar/chart-horizontal-bar.component';
import { ChartProgressBarComponent } from '../chart/chart-progress-bar/chart-progress-bar.component';
import { ChartVersusComponent } from '../chart/chart-versus/chart-versus.component';
import { SlugifyPipe } from '../../pipes/slugify.pipe';

@Component({
  selector: 'app-candidate-results',
  standalone: true,
  imports: [ CommonModule, TranslateModule, SlugifyPipe, ChartRadarComponent, ChartHorizontalBarComponent, ChartProgressBarComponent, ChartVersusComponent ],
  templateUrl: './candidate-results.component.html',
  styleUrls: [ './candidate-results.component.scss' ]
})
export class CandidateResultsComponent implements OnInit {

  @Input() report: any;
  @Input() mapper: string;

  constructor() { }

  ngOnInit() {
    this.setHiddenAllSections();
    this.showCompetencieComponent();
    this.formatDigitalHeroWidgets();
  }

  toggleResult(index) {
    this.report[ index ].hidden = this.report[ index ].hidden ? false : true;
  }

  formatDigitalHeroWidgets() {
    if (this.mapper === 'digital_hero' || this.mapper === 'building_mia') {
      this.report = this.report.extended_report.map(item => {
        if (item.widget && item.widget.length > 0) {
          let widgets = { first: [], second: [] };
          item.widget.forEach(widget => {
            switch (widget.type) {
              case 'radar':
                widgets.first.push(widget);
                break;
              case 'versus':
                widgets.first.push(widget);
                break;
              case 'progress':
                widgets.second.push(widget);
                break;
              case 'horizontal':
                widgets.second.push(widget);
                break;
            }
          });
          item.widget = widgets;
        }
        return item;
      });
    }
  }

  setHiddenAllSections() {
    this.report.map((element) => {
      element.hidden = true;
      element.show = false;
    });
  }

  setShowAllSections() {
    this.report.map((element) => {
      element.show = true;
    });
  }

  showCompetencieComponent() {

    let i;

    if (this.report[ 0 ].type == 'VIDEOASK') {
      this.setShowAllSections();
    } else {

      for (i = 1; i < this.report.length; i++) {
        var empty_text = this.report[ i ].text ? this.report[ i ].text.filter(arr => arr.content != "") : [];
        if (empty_text.length > 0) {
          this.report[ i ].show = true;
        }
      }

      var empty_widget = this.report[ 0 ].widget.filter(arr => arr.data != "");
      if (empty_widget.length > 0) {
        this.report[ 0 ].show = true;
      }
    }
  }

}




