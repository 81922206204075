<div class="dashboard-candidate__container">
  <div class="loader" *ngIf="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <a [routerLink]="['.']" aria-label="Close" class="close">
    <span aria-hidden="true">&times;</span>
  </a>

  <div class="dashboard-candidate__actions">
    <div class="tittle-report">
      <h3>{{ "__personalReport" | translate }}</h3>
    </div>
    <div class="candidate__actions_element">
      <button (click)="openUrlToPrint()" class="bubble">
        <i class="fas fa-print blue-color"></i>
      </button>
      <button (click)="getUrlToShareReport()" class="bubble">
        <i class="fa fa-share-square blue-color"></i>
      </button>
    </div>
  </div>

  <div class="dashboard-candidate__content">
    <section
      class="candidate-section client-info__content"
      *ngIf="candidate.results"
    >
      <div class="email-candidate">
        <h4>{{ candidate?.email }}</h4>
      </div>
      <div class="section__element">
        <div *ngFor="let result of candidate.results">
          <button
            class="report-tab"
            (click)="setReport(result.id)"
            [ngClass]="
              isSelectedButton(result.id)
                ? 'active-button'
                : 'non-active-button'
            "
          >
            <span class="section__element_title">{{
              result.instrument.name
            }}</span>
          </button>
        </div>
        <div class="loader" *ngIf="waitingReport">
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
      </div>

      <div class="section__element_results">
        <app-candidate-results
          *ngIf="!waitingReport && thereIsReport"
          [mapper]="candidate.results[0].instrument.mapper"
          [report]="report"
        ></app-candidate-results>

        <div class="hint-block--purple" *ngIf="!thereIsReport">
          <p>{{ "__resultsPending" | translate }}</p>
        </div>
      </div>
    </section>

    <div class="hint-block--purple" *ngIf="!candidate.results">
      {{ "__noResultsCandidate" | translate }}
    </div>
  </div>
</div>

<ng-template #shareReport>
  <div class="modal-header">
    <div class="modal-title">
      <h4 class="modal-title pull-left">{{ "__publicReport" | translate }}</h4>
      <p>{{ "__linkBelowPublic" | translate }}</p>
    </div>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="report-url">
      <input
        #reportUrlElement
        [value]="reportUrl"
        [size]="reportUrl.length"
        readonly
      />
      <i
        class="material-icons"
        matTooltip="Copy link"
        [matTooltipPosition]="'below'"
        (click)="copyTextToClipboard(reportUrlElement)"
        >file_copy</i
      >
    </div>
    <div class="report-url">
      <input
        #reportUrlCodiceElement
        [value]="reportUrlCodice"
        [size]="reportUrlCodice.length"
        readonly
      />
      <i
        class="material-icons"
        matTooltip="Copy link"
        [matTooltipPosition]="'below'"
        (click)="copyTextToClipboard(reportUrlCodiceElement)"
        >file_copy</i
      >
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-naked" (click)="modalRef.hide()">
      {{ "__close" | translate }}
    </button>
  </div>
</ng-template>
