<div class="client-appearance-builder">

  <div class="client-appearance-builder__content">

    <div class="client-appearance-builder__step">
      <div class="client-appearance-builder__header">
        <div class="text-container">
          <h2>{{'__setAppearance' | translate }}</h2>
          <p>{{'__lookFeel' | translate}}</p>
        </div>
      </div>

      <div *ngIf="appearance" class="client-appearance-container">

        <section class="client-info">
          <div class="section-info__header" (click)="edit.graphics = !edit.graphics">
            <h5 class="section__header">
              <i class="material-icons">format_color_fill</i>
              <b>{{'__graphicElements' | translate }}.</b> {{'__brandColors' | translate }}
            </h5>
            <i class="material-icons" [innerHtml]="edit.graphics ? 'expand_less' : 'expand_more'"></i>
          </div>

          <div class="client-info__content" *ngIf="edit.graphics">
            <div class="specific-info">
              <div class="specific-info__row">
                <div class="specific-info__col">
                  <p class="specific-info__title">
                    <b>{{'__colors' | translate }}.</b> {{'__editColors' | translate }}
                  </p>
                  <div class="specific-info__content color-selection">
                    <b>{{'__primaryColor' | translate }}</b>
                    <div class="color-picker-container">
                      <div class="color-sample" [style.background]="appearance.primary_color" (click)="getElement('primary-color').click()" ></div>
                      <input id="primary-color" class="field custom-input" [(colorPicker)]="appearance.primary_color" [value]="appearance.primary_color" [cpCancelButton]="true" [cpOutputFormat]="'hex'"/>
                    </div>
                  </div>

                  <div class="specific-info__content color-selection">
                    <b>{{'__secondaryColor' | translate }} </b>
                    <div class="color-picker-container">
                      <div class="color-sample" [style.background]="appearance.secondary_color" (click)="getElement('secondary-color').click()" ></div>
                      <input id="secondary-color" class="field custom-input" [(colorPicker)]="appearance.secondary_color" [value]="appearance.secondary_color" [cpCancelButton]="true" [cpOutputFormat]="'hex'"/>
                    </div>
                  </div>

                  <div class="specific-info__content color-selection">
                    <b> {{'__textColor' | translate }} </b>
                    <div class="color-picker-container">
                      <div class="color-sample" [style.background]="appearance.text_color" (click)="getElement('text-color').click()" ></div>
                      <input id="text-color" class="field custom-input" [(colorPicker)]="appearance.text_color" [value]="appearance.text_color" [cpCancelButton]="true" [cpOutputFormat]="'hex'"/>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </section>

        <section class="client-info">
          <div class="section-info__header" (click)="edit.media = !edit.media">
            <h5 class="section__header">
              <i class="material-icons">perm_media</i>
              <b>{{'__multiDocuments' | translate }}.</b> {{'__uploadArchives' | translate }}</h5>
            <i class="material-icons" [innerHtml]="edit.media ? 'expand_less' : 'expand_more'"></i>
          </div>

          <div class="client-info__content" *ngIf="edit.media">
            <div class="specific-info">
              <div class="specific-info__row">
                <div class="specific-info__col">
                  <app-upload-image
                    [(imgUrl)]="appearance.logo"
                    [title]="'__logoType' | translate "
                    [comments]="'__tipHorizontal' | translate "
                  ></app-upload-image>
                </div>
              </div>
            </div>
          </div>
        </section>

        <button (click)="save()" class="btn btn-main">{{'__saveChanges' | translate }}</button>
      </div>

    </div>
  </div>
</div>
