import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-chart-progress-bar',
  standalone: true,
  imports: [ MatProgressBarModule, CommonModule ],
  templateUrl: './chart-progress-bar.component.html',
  styleUrls: [ './chart-progress-bar.component.scss' ]
})
export class ChartProgressBarComponent implements OnInit {

  @Input() data: number[];
  @Input() labels: string[];
  @Input() name: string;
  @Input() max: number;

  constructor() { }

  ngOnInit() {
    this.setMaxValue();
  }

  setMaxValue() {
    this.max = this.max ? this.max : Math.max.apply(null, this.data);
  }

}
