<div class="chart-wrapper">
  <p *ngFor="let value of data; let i = index;" class="row_progressbar"> 
    
    <mat-progress-bar class="progressbar" mode="determinate" value="{{value*100/max}}"></mat-progress-bar>
    <label class="medium">
      <p *ngIf="labels">{{labels[i]}}</p> 
      <span class="total"> {{value}}/{{max}} </span>
    </label>
  </p>
  
</div>