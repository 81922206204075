import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { Candidate } from '../../classes/candidate';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { CandidateStatusPipe } from '../../pipes/candidate-status.pipe';
import { AlertsService } from '../../services/alerts.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CandidateService } from '../../services/candidate.service';
import { Subscription } from 'rxjs';
import { Settings } from '../../classes/settings';
import { CandidateResultsComponent } from '../candidate-results/candidate-results.component';

@Component({
  selector: 'app-candidate-dashboard',
  standalone: true,
  imports: [ CommonModule, TranslateModule, MatTooltipModule, RouterModule, CandidateResultsComponent ],
  templateUrl: './candidate-dashboard.component.html',
  styleUrls: [ './candidate-dashboard.component.scss' ],
})
export class CandidateDashboardComponent implements OnInit {

  candidate: Candidate = new Candidate();
  candidate$: Subscription;
  @Input() candidateId: number;
  @Input() projectId: number;
  status: string = '';
  thereIsReport: boolean = false;
  report: any[] = [];
  reports: any;
  currentResult: any[];

  @ViewChild('shareReport', { read: TemplateRef })
  shareReportModal: TemplateRef<any>;
  modalRef: BsModalRef;
  reportUrl: string = '';
  reportUrlCodice: string = '';

  loading = true;
  waitingReport = false;
  actualReport: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private candidateService: CandidateService,
    private candidateStatus: CandidateStatusPipe,
    private alertsService: AlertsService,
    private modalService: BsModalService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.getCandidateDetail();
  }

  getCandidateDetail() {
    this.candidate$ = this.activatedRoute.queryParamMap.subscribe(
      paramMap => {
        this.loading = true;
        const candidateId = +paramMap.get('candidate');
        if (candidateId) {
          this.projectService.getProjectDetailCandidate(this.projectId, candidateId).pipe().subscribe(
            response => {
              this.candidate = response;
              this.status = this.candidateStatus.transform(this.candidate);
              this.loading = false;
              if (this.candidate.results[ 0 ].done) {
                this.setReport(this.candidate.results[ 0 ].id);
                this.reports = this.candidate.results;
              } else {
                this.thereIsReport = false;
                this.report = [];
              }
            }, error => this.alertsService.setAlert({ type: "error", message: this.translateService.instant("__candidateCouldNotBeRead") })
          );
        }
      }
    );
  }

  showModal(modal) {
    this.modalRef = this.modalService.show(modal);
  }

  closeModal() {
    this.modalRef.hide();
  }

  sendReminder() {
    this.projectService.sendReminder(this.projectId, this.candidate.id).pipe().subscribe(
      response => this.alertsService.setAlert({ type: "success", message: this.translateService.instant("__reminderCorrectly") }),
      error => this.alertsService.setAlert({ type: "error", message: this.translateService.instant("__reminderNotSent") }),
    );
  }

  getUrlToShareReport() {
    this.reportUrl = window.location.origin + '/public-report/' + this.currentResult[ 0 ].token;
    this.reportUrlCodice = Settings.CODICE_URL + '?t=' + this.currentResult[ 0 ].token;
    this.showModal(this.shareReportModal);
  }

  openUrlToPrint() {
    this.reportUrl = window.location.origin + '/public-report/' + this.currentResult[ 0 ].token;
    window.open(this.reportUrl + '?print=1');
  }

  copyTextToClipboard(element) {
    element.select();
    document.execCommand('copy');
    element.setSelectionRange(0, 0);
  }


  setReport(result_id: number) {
    this.waitingReport = true;
    this.currentResult = this.candidate.results.filter(element => element.id == result_id);
    this.actualReport = result_id;
    this.candidateService.getReport(result_id).pipe().subscribe(
      response => {
        this.report = response;
        this.waitingReport = false;
        this.thereIsReport = true;
        this.actualReport = result_id;
      },
      error => {
        this.waitingReport = false;
        this.thereIsReport = false;
      }
    );
  }

  isSelectedButton(result_id: number) {
    if (result_id == this.actualReport) return true;
    return false;
  }
}
