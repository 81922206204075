import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateService } from '../../services/candidate.service';
import { AlertsService } from '../../services/alerts.service';
import { CandidateStatusPipe } from '../../pipes/candidate-status.pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CandidateResultsComponent } from '../candidate-results/candidate-results.component';

@Component({
  selector: 'app-public-report',
  standalone: true,
  imports: [ CommonModule, TranslateModule, CandidateResultsComponent ],
  templateUrl: './public-report.component.html',
  styleUrls: [ './public-report.component.scss' ],
  providers: [ CandidateStatusPipe ]
})
export class PublicReportComponent implements OnInit {

  token: string;
  report: any;
  status: string = '';

  loading = true;
  waitingReport = true;
  thereIsReport: boolean = false;

  constructor(
    private candidateService: CandidateService,
    private alertsService: AlertsService,
    private activatedRoute: ActivatedRoute,
    private candidateStatus: CandidateStatusPipe,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    this.setReport();
  }

  setReport() {
    this.candidateService.getPublicReport(this.token).pipe().subscribe(
      response => {
        this.loading = false;
        this.waitingReport = false;
        this.thereIsReport = true;
        this.report = response;
        this.status = this.candidateStatus.transform(this.report);
        this.printReportOnLoad();
      },
      error => this.alertsService.setAlert({ type: 'error', message: this.translateService.instant("__reportNotLoaded") })
    );
  }

  printPDF() {
    if (this.waitingReport == false) {
      window.print();
    }
  }

  printReportOnLoad() {
    let url = window.location.toString();
    if (this.waitingReport == false && url.match('print=1') != null) {
      setTimeout(function () { window.print(); }, 1500);

    }
  }
}
