import { Component, OnInit, Input } from '@angular/core';
import { ChartRenderComponent } from '../chart-render/chart-render.component';

@Component({
  selector: 'app-chart-radar',
  standalone: true,
  imports: [ ChartRenderComponent ],
  templateUrl: './chart-radar.component.html',
  styleUrls: [ './chart-radar.component.scss' ]
})
export class ChartRadarComponent implements OnInit {

  @Input() data;
  @Input() labels: string[];
  @Input() name: string;
  @Input() scale: number;

  config = {
    chart: { polar: true, type: 'line' },
    xAxis: { categories: [], tickmarkPlacement: 'on', lineWidth: 0, labels: { style: { fontSize: '12pt' }, allowOverlap: true } },
    yAxis: { gridLineInterpolation: 'polygon', min: 0 },
    tooltip: {
      headerFormat: '<span style="font-size:8pt">{point.key} : </span><table>',
      pointFormat: '<span style="font-size:8pt">{point.y} </span>'
    }
  };

  constructor() { }

  ngOnInit() {
    if (this.scale !== undefined) this.scale++;
  }

}
