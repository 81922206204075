import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(public auth: UserService, public router: Router) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.goToMyPath(childRoute, state);
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.goToMyPath(route, state);
  }

  goToMyPath(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const shouldNavigateToLogin = !this.auth.checkIsLogged() || state.url === '/';
    if (shouldNavigateToLogin) {
      this.router.navigate([ 'login' ]);
      return false;
    }
    return this.auth.checkHasPermissions(route.data[ 'permission' ]);
  }

}

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {

  constructor(public auth: UserService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.goToMyPath();
  }


  goToMyPath(state = ''): boolean {
    if (this.auth.checkIsLogged()) {
      const mypath = this.auth.getMyInitialPath();
      this.router.navigate([ mypath ]);
      return false;
    }
    return true;
  }


}
