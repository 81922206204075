<nav class="toolbar">
  <div class="personal-settings">
    <span class="user-account">
      <i class="material-icons">person_pin</i>
      <div class="btn-group" dropdown>
        <button
          dropdownToggle
          type="button"
          id="user-account__dropdown"
          class="btn btn-outline dropdown-toggle"
        >
          {{ user?.name }}
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li role="menuitem">
            <span
              class="dropdown-item pointer"
              (click)="showModal(changePassword)"
              >{{ "__changePassword" | translate }}</span
            >
            <span class="dropdown-item pointer" (click)="logout()">{{
              "__logout" | translate
            }}</span>
          </li>
        </ul>
      </div>
    </span>
  </div>
</nav>

<ng-template #changePassword>
  <div class="modal-header">
    <div class="modal-title">
      <h4 class="modal-title pull-left">
        {{ "__changePassword" | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" novalidate>
      <label
        class="password-label"
        for="password"
        [innerHTML]="'__currentPass' | translate"
      ></label>
      <input
        type="password"
        [placeholder]="'__currentPassword' | translate"
        formControlName="currentPassword"
      />

      <label
        class="password-label"
        for="password"
        [innerHTML]="'__enterNewPass' | translate"
      ></label>
      <input
        type="password"
        [placeholder]="'__newPassword' | translate"
        formControlName="newPassword"
      />

      <label
        class="password-label"
        for="password"
        [innerHTML]="'__confirmPass' | translate"
      ></label>
      <input
        type="password"
        [placeholder]="'__repeatPassword' | translate"
        formControlName="confirmPassword"
      />

      <div
        *ngIf="!form.valid && newPassword.value"
        class="error-hints-container pass-requirements"
      >
        <h6>{{ "__passwordsMust" | translate }}:</h6>
        <ul>
          <li>
            <i
              class="material-icons"
              [ngClass]="{
                passed: !form.controls['newPassword'].hasError('minlength')
              }"
            >
              {{
                form.controls["newPassword"].hasError("minlength")
                  ? "radio_button_unchecked"
                  : "check_circle_outline"
              }}
            </i>
            <span>
              {{ "__passwordLenght" | translate }}
            </span>
          </li>
          <li>
            <i
              class="material-icons"
              [ngClass]="{
                passed: !form.controls['newPassword'].hasError('hasUppercase')
              }"
            >
              {{
                form.controls["newPassword"].hasError("hasUppercase")
                  ? "radio_button_unchecked"
                  : "check_circle_outline"
              }}
            </i>
            <span>
              {{ "__passwordUppercase" | translate }}
            </span>
          </li>

          <li>
            <i
              class="material-icons"
              [ngClass]="{
                passed: !form.controls['newPassword'].hasError('hasLowercase')
              }"
            >
              {{
                form.controls["newPassword"].hasError("hasLowercase")
                  ? "radio_button_unchecked"
                  : "check_circle_outline"
              }}
            </i>
            <span>
              {{ "__passwordLowercase" | translate }}
            </span>
          </li>

          <li>
            <i
              class="material-icons"
              [ngClass]="{
                passed: !form.controls['newPassword'].hasError('hasNumber')
              }"
            >
              {{
                form.controls["newPassword"].hasError("hasNumber")
                  ? "radio_button_unchecked"
                  : "check_circle_outline"
              }}
            </i>
            <span>
              {{ "__passwordNumber" | translate }}
            </span>
          </li>

          <li>
            <i
              class="material-icons"
              [ngClass]="{
                passed: !form.controls['newPassword'].hasError('hasSymbol')
              }"
            >
              {{
                form.controls["newPassword"].hasError("hasSymbol")
                  ? "radio_button_unchecked"
                  : "check_circle_outline"
              }}
            </i>
            <span>
              {{ "__passwordSymbol" | translate }}
            </span>
          </li>

          <li>
            <i
              class="material-icons"
              [ngClass]="{
                passed:
                  !form.controls['confirmPassword'].hasError('passwordMismatch')
              }"
            >
              {{
                form.controls["confirmPassword"].hasError("passwordMismatch")
                  ? "radio_button_unchecked"
                  : "check_circle_outline"
              }}
            </i>
            <span>
              {{ "__passwordsMatch" | translate }}
            </span>
          </li>
        </ul>
      </div>

      <p *ngIf="invalidPassReset" class="error-msg">
        * {{ "__invalidPasswordResetError" | translate }}
      </p>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-naked" (click)="closeModal()">
      {{ "__cancel" | translate }}
    </button>
    <button
      class="btn btn-main"
      (click)="setPassword()"
      [disabled]="!form.valid"
    >
      {{ "__setPassBtn" | translate }}
    </button>
  </div>
</ng-template>
