import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { HighchartService } from '../../../services/highchart.service';
import { TranslateService } from '@ngx-translate/core';
declare const Highcharts: any;

@Component({
  selector: 'app-chart-render',
  standalone: true,
  templateUrl: './chart-render.component.html',
  styleUrls: [ './chart-render.component.scss' ]
})
export class ChartRenderComponent implements OnInit {

  _arrayData: number[];
  _dictOfArraysData: any; // Example: {candidate: [3, 5, 7], profile_reference: [4, 6, 6]}
  @Input() set data(value) {
    if (value instanceof Array) {
      this._arrayData = value;
    } else {
      if (this.highchartsService.isDictionary(value)) {
        this._dictOfArraysData = value;
      }
    }
  }
  @Input() labels: string[];
  @Input() scale: number;
  @Input() name: string;
  @Input() max: number;

  _config = {
    chart: { height: 400 },
    title: { text: '' },
    xAxis: { categories: [], labels: { style: { fontSize: '12pt' } } },
    yAxis: { min: 0, max: null },
    credits: { enabled: false },
    tooltip: {
      headerFormat: '<span style="font-size:12pt">{point.key} : </span><table>',
      pointFormat: '<span style="font-size:12pt">{point.y} </span>'
    },
    legend: { enabled: false },
    series: []
  };
  @Input() set config(value: any) {
    this._config = Object.assign(this._config, value);
  }

  constructor(
    private hostElement: ElementRef,
    private highchartsService: HighchartService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.setData();
    Highcharts.chart(this.hostElement.nativeElement, this._config);
  }

  setData() {
    if (this._arrayData) {
      this.hideNullValuesFromArrayData();
      this._config.series = this.highchartsService.getDataFromArray(this.name, this._arrayData);
    }
    if (this._dictOfArraysData) {
      this._config.series = this.highchartsService.getDataFromDictOfArrays(this.name, this._dictOfArraysData);
    }

    this._config.xAxis.categories = this.translateArray(this.labels);
    this._config.yAxis.max = this.scale;

  }

  hideNullValuesFromArrayData() {
    let labels = [];
    let checkedData = [];
    this._arrayData.forEach((item, index) => {
      if (item !== null) {
        checkedData.push(item);
        labels.push(this.labels[ index ]);
      }
    });
    this._arrayData = checkedData;
    this.labels = labels;
  }
  //util
  translateArray(array: string[]) {
    var i;
    for (i = 0; i < this.labels.length; i++) {
      if (array[ i ] != "") {
        array[ i ] = this.translateService.instant("__" + this.slugify(array[ i ]));
      }
    }
    return array;
  }

  slugify(text: string) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text;
  }

}
