import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const DOUBLE_DIGIT_MIN = 2;
const MINUTES_LENGTH = 6;


@Pipe({
  name: 'verboseTime',
  standalone: true,
})


export class VerboseTimePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  times = {
    year: 31557600,
    month: 2629746,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
  };
  transform(value: any = '', args?: any): any {
    let time_string, avg_time: string = '';
    let seconds = value;
    if (value === "0") {
      return this.translateService.instant("__notAverageTime");
    }
    for (var key in this.times) {
      if (Math.floor(+seconds / this.times[ key ]) > 0) {

        time_string = Math.floor(seconds / this.times[ key ]).toString();
        avg_time += this.formatTime(time_string);
        seconds = seconds - this.times[ key ] * Math.floor(seconds / this.times[ key ]);

      }
    }
    return this.finalCheck(avg_time);
  }

  formatTime(time_string: string) {
    if (time_string.length < DOUBLE_DIGIT_MIN) {
      time_string = ':0' + time_string;
    } else if (time_string.length == DOUBLE_DIGIT_MIN) {
      time_string = ':' + time_string;
    }
    return time_string;
  }

  finalCheck(avg_time) {
    if (avg_time.length == MINUTES_LENGTH) {
      avg_time = '00' + avg_time;
    }
    if (avg_time.charAt(0) == ':') {
      avg_time = avg_time.substring(1);
    }
    return avg_time;
  }
}
