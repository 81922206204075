<div class="page-wrapper">

  <div class="client-add-instruments-header">
    <div class="text-container">
      <h2>{{'__addInsts' | translate }}</h2>
      <p>{{'__descAddInsts' | translate}}</p>
    </div>
  </div>

  <div class="select-instruments-wrapper">

    <!--a draggable element-->

    <div class="select-instruments-container">
      <div class="select-instruments-title">
        {{'__availableInst' | translate}}
      </div>

      <div class="instruments-list-container">
        <div class="instrument-item" *ngFor="let instrument of availableInstruments; let i = index;">
          <div [dndDraggable]="instrument" [dndEffectAllowed]="'move'" (dndMoved)="onDraggableMoved($event, instrument, i)">
            <div dndDragImageRef>{{instrument.name}}</div>
          </div>
        </div>
      </div>


    </div>



    <!--a dropzone-->
    <!--to allow dropping content that is not [dndDraggable] set dndAllowExternal to true-->
    <section class="select-instruments-container">

      <div class="select-instruments-title">
        {{'__selectedInst' | translate }}
      </div>
      <div dndDropzone class="instruments-list-container">
          <option *ngFor="let instrument of clientInstruments" [value]="instrument.id">{{instrument.name}} </option>
        <div class="instrument-item"  *ngFor="let instrument of selectedInstruments">
            {{instrument.name}} 
        </div>
      </div>

    </section>
    <div class="btn-area">
        <button class="btn btn-naked" (click)="resetInstruments()"> {{'__clear' | translate }} </button>
        <br>
        <button class="btn btn-primary" (click)="addInstruments()"> {{'__save' | translate }} </button>

      </div>
  </div>

</div>
