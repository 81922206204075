import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ProjectService } from '../../services/project.service';
import { UnslugifyPipe } from '../../pipes/unslugify.pipe';
import { CommonModule } from '@angular/common';
import { ChartPieComponent } from '../chart/chart-pie/chart-pie.component';
import { VerboseTimePipe } from '../../pipes/verbose-time.pipe';

@Component({
  selector: 'app-project-analytics',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ChartPieComponent,
    VerboseTimePipe
  ],
  templateUrl: './project-analytics.component.html',
  styleUrls: [ './project-analytics.component.scss' ],
  animations: [
    trigger('openDetail', [
      state('true', style({
        transform: 'translateX(0%)'
      })),
      state('false', style({
        transform: 'translateX(100%)'
      })),
      transition('true => false', animate('200ms ease-in')),
      transition('false => true', animate('200ms ease-in'))
    ]),
    trigger('collapseTable', [
      state('true', style({
        width: '100%'
      })),
      state('false', style({
        width: '50%'
      })),
      transition('true => false', animate('200ms ease-in')),
      transition('false => true', animate('200ms ease-in'))
    ])
  ]
})
export class ProjectAnalyticsComponent implements OnInit {

  private _project: number;
  @Input() set project(value: number) {
    this._project = value;
    this.setAnalytics();
  }
  get project(): number {
    return this._project;
  }

  private _candidatesAdded: number;
  @Input() set added(value: number) {
    this._candidatesAdded = value;
    this.setAnalytics();
  }
  get added(): number {
    return this._candidatesAdded;
  }

  loading: boolean = false;
  analytics: any;
  candidatesByStatusData: { name: string, y: number; }[] = [];
  candidatesByStatusSeries: { name: string, colorByPoint: Boolean, data: { y: number, name: string; }[], innerSize: string; }[] = [];

  constructor(
    private projectService: ProjectService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.setAnalytics();
    this.subscribeChangeCandidatesByProject();
  }

  setAnalytics() {
    this.loading = true;
    this.projectService.getAnalytics(this._project).pipe().subscribe(
      response => {
        this.loading = false;
        this.analytics = response;
        this.setCandidatesByStatusData();
      }
    );
  }

  setCandidatesByStatusData() {
    const unslugifyPipe = new UnslugifyPipe();
    this.candidatesByStatusData = [];
    Object.keys(this.analytics.candidates_by_status).forEach(key => {
      if (key !== 'total') {
        this.candidatesByStatusData.push({ name: this.translateService.instant(`__${key}`), y: this.analytics.candidates_by_status[ key ] });
      }
    });
    this.candidatesByStatusSeries = [ { name: "Candidate by status", colorByPoint: true, data: this.candidatesByStatusData, innerSize: '85%' } ];
  }

  subscribeChangeCandidatesByProject() {
    this.projectService.currentCandidatesProject.subscribe(message => {
      if (message == 'true') {
        this.setAnalytics();
      }
    });
  }

}
