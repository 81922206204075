import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, ElementRef, SimpleChanges } from '@angular/core';
declare const Highcharts: any;

@Component({
  selector: 'app-chart-pie',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './chart-pie.component.html',
  styleUrls: [ './chart-pie.component.scss' ]
})
export class ChartPieComponent implements OnInit {

  @Input() data: { y: number, name: string; }[];
  @Input() series: { name: string, colorByPoint: Boolean, data: { y: number, name: string; }[]; }[];
  @Input() name: string;
  @Input() height: number;

  config = {
    chart: { type: 'pie', plotShadow: false, plotBackgroundColor: null, width: 400, height: 400 },
    colors: [ '#163671', '#eac97b', '#558ff3' ],
    title: { text: '' },
    credits: { enabled: false },
    tooltip: { enabled: false },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true,
        size: 180,
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemMarginTop: 10,
      itemMarginBottom: 10,
      labelFormatter: function () {
        return '<span style="color:' + this.color + '">' + this.name + ': </span><b> ' + this.y + '<br/>';
      }
    },
    series: [ {
    } ]
  };

  constructor(
    private hostElement: ElementRef
  ) { }

  ngOnInit() {
    this._renderChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    this._renderChart();
  }

  _setData() {
    this.config.series = this.series;
    this.config.chart.height = this.height;
    // this.config.series = [{ name: this.name, colorByPoint: true, data: this.data }];
  }

  _renderChart() {
    this._setData();
    Highcharts.chart(this.hostElement.nativeElement.querySelector('.chart'), this.config);
  }

}
